<template lang="pug">
.dropdown(v-if="options")
  input.input(
    v-model="searchFilter",
    :disabled="disabled",
    :placeholder="placeholder"
    @focus="showOptions()",
    @blur="exit()",
    @keyup="keyMonitor"
  )
  .dropdown-content(v-show="optionsShown")
    .dropdown-item(
      v-for="(option, index) in filteredOptions", 
      :key="index", 
      @mousedown="selectOption(option)"
    ) 
      | {{ option.name || option.id || "-"}}
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    optionNames: {
      type: Array,
      required: true,
      default: () => [],
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Select an option',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxItem: {
      type: Number,
      required: false,
      default: 6,
    },
  },
  data() {
    return {
      selected: {},
      options: {},
      optionsShown: false,
      searchFilter: '',
    };
  },
  computed: {
    filteredOptions() {
      const filtered = [];
      const regOption = new RegExp(this.searchFilter, 'ig');
      this.options.forEach(option => {
        if (this.searchFilter.length < 1 || option.name.match(regOption)) {
          if (filtered.length < this.maxItem) {
            filtered.push(option);
          }
        }
      });
      return filtered;
    },
  },
  watch: {
    searchFilter() {
      if (this.filteredOptions.length === 0) {
        this.selected = {};
      } else {
        const [selected] = this.filteredOptions;
        this.selected = selected;
      }
      this.$emit('filter', this.searchFilter);
    },
  },
  created() {
    this.prepareOptions();
  },
  methods: {
    prepareOptions() {
      this.options = this.optionNames.map((name, idx) => {
        return { name, id: idx + 1 };
      });
    },
    selectOption(option) {
      this.selected = option;
      this.optionsShown = false;
      this.searchFilter = this.selected.name;
      this.$emit('selected', this.selected.name);
    },
    showOptions() {
      if (!this.disabled) {
        this.searchFilter = '';
        this.optionsShown = true;
      }
    },
    exit() {
      if (!this.selected.id) {
        this.selected = {};
        this.searchFilter = '';
      } else {
        this.searchFilter = this.selected.name;
      }
      this.$emit('selected', this.selected.name);
      this.optionsShown = false;
    },
    // Selecting when pressing Enter
    keyMonitor(event) {
      if (event.key === 'Enter' && this.filteredOptions[0]) {
        this.selectOption(this.filteredOptions[0]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;
  display: block;
  margin: auto;

  .input {
    background: #fff;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    border: 1.5px solid transparent;
    border-bottom-color: #e8e5d2;
    font-weight: 500;
    display: block;
    width: 100%;

    &::placeholder {
      opacity: 0.4;
    }
  }

  .dropdown-content {
    position: absolute;
    background-color: #fff;
    max-height: 248px;
    width: 100%;
    border: 1px solid #e7ecf5;
    box-shadow: 0px -8px 34px 0px rgba(0, 0, 0, 0.05);
    overflow: auto;
    z-index: 1;

    .dropdown-item {
      color: black;
      line-height: 1em;
      padding: 8px;
      text-decoration: none;
      display: block;
      cursor: pointer;

      &:hover {
        background-color: #e7ecf5;
      }
    }
  }

  .dropdown:hover .dropdowncontent {
    display: block;
  }
}
</style>
