<template>
  <div class="confirm-form">
    <img
      src="@/assets/icons/confirm-form/confirm.svg"
      class="confirm-form__image"
    />
    <p class="confirm-form__title">{{ $t('confirm-form.title') }}</p>
    <p class="confirm-form__text">{{ $t('confirm-form.text') }}</p>
    <ErrorCard
      v-show="errorText"
      :key="errorText"
      :error-text="errorText"
      class="confirm-form__error"
    />
    <UiInput
      v-model="code"
      :title="$t('confirm-form.code')"
      :placeholder="$t('confirm-form.code').toUpperCase()"
      :max-length="6"
      :show-error="codeError"
      class="confirm-form__code"
    />
    <UiButton class="confirm-form__button" @click="submitForm">
      {{ $t('confirm-form.button') }}
    </UiButton>
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser';

import { getRequestInfo, confirmCode } from '@/api/payments.js';
import UiButton from '@/components/ui/UiButton.vue';
import UiInput from '@/components/ui/UiInput.vue';

import ErrorCard from '../Card/ErrorCard.vue';

export default {
  name: 'ConfirmForm',
  components: { UiInput, UiButton, ErrorCard },
  data: () => ({
    data: {},
    code: '',
    errorText: '',
    codeRegex: /^.{4,6}$/,

    isLoading: true,
    codeError: false,
  }),

  computed: {
    isSubmitButtonDisabled() {
      if (!this.isFieldValid('code')) return 'disabled';
      return '';
    },
  },

  watch: {
    code() {
      this.codeError = false;
    },
  },

  mounted() {
    this.validateRequest();
  },

  methods: {
    async validateRequest() {
      const requestId = window.location.pathname.replace(/^.*\/([^/]+)$/, '$1');
      const loader = this.$loading.show({
        loader: 'dots',
        width: 50,
        opacity: 1,
        transition: null,
      });

      try {
        this.data = await getRequestInfo(requestId);

        if (!this.data.status) return this.$router.push('/not-found');
        if (
          this.data.merchantData &&
          this.data.merchantData?.confirmationCodeRegex
        )
          this.codeRegex = new RegExp(
            this.data.merchantData.confirmationCodeRegex,
          );
      } catch (err) {
        Sentry.captureException(err);
        this.$router.push('/not-found');
      } finally {
        loader.hide();
        this.isLoading = false;
      }
    },

    isFieldValid(fieldName) {
      const value = this.code;

      if (!this.codeRegex.test(value)) {
        this.codeError = true;
      }

      if (!value) return false;

      if (fieldName === 'code' && !this.codeRegex.test(value)) {
        return false;
      }

      return true;
    },

    async submitForm() {
      if (!this.isFieldValid('code')) return false;

      const loader = this.$loading.show({
        loader: 'dots',
        width: 50,
        opacity: 1,
        transition: null,
      });

      try {
        const response = await confirmCode(
          this.data.requestId,
          this.code,
        ).catch(err =>
          err.response ? { error: err.response.data.error } : err,
        );

        if (response.error) {
          Sentry.captureException(response.error);
          this.errorText = response.error;
          return;
        }

        const { method, source, data, targetBlank = false } = response.data;

        const form = document.createElement('form');
        form.setAttribute('action', source);
        form.setAttribute('method', method);

        Object.keys(data).forEach(name => {
          const input = document.createElement('input');
          input.setAttribute('name', name);
          input.setAttribute('value', data[name]);
          input.setAttribute('type', 'hidden');
          form.appendChild(input);
        });

        document.querySelector('body').appendChild(form);

        if (targetBlank) {
          form.setAttribute('target', '_blank');
          form.setAttribute('rel', 'noopener noreferrer');
        }

        form.submit();
      } catch (err) {
        Sentry.captureException(err);
      } finally {
        loader.hide();
      }
    },
  },
};
</script>

<style lang="scss">
.confirm-form {
  width: 343px;
  margin: 0 auto;
  padding-top: 40px;

  @media (max-width: 480px) {
    width: 100%;
  }

  &__image {
    display: block;
    margin: 0 auto;
    width: 48px;
    height: 54px;
  }

  &__title {
    margin-top: 16px;
    color: rgba(14, 16, 17, 1);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.33000001311302185px;
    text-align: center;
  }

  &__text {
    color: rgba(111, 118, 126, 1);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.009999999776482582px;
    text-align: center;
    margin-top: 8px;
  }

  &__code {
    margin-top: 24px;
  }

  &__button {
    margin-top: 24px;
  }

  &__error {
    margin: 32px 0 16px;
  }
}
</style>
