/**
 * @author feod0r
 */

const cardBinMap = {
  americanExpress: [34, 37],
  maestro: [5018, 5020, 5038, 5893, 6304, 6759, 6761, 6762, 6763],
  humo: [9860],
  uzcard: [8600],
};

export const merchantRequiredFields = {
  uzpay: ['pan', 'expiration_date'],
};

// https://en.wikipedia.org/wiki/Payment_card_number
// eslint-disable-next-line import/prefer-default-export
export const getCardType = pan => {
  if (/^220[0-4]/.test(pan)) return 'mir';

  if (/^4/.test(pan)) return 'visa';

  if (/^3[47]/.test(pan)) return 'american-express';

  const iin4 = Number(pan.substr(0, 4));

  if ((iin4 >= 2221 && iin4 <= 2720) || /^5[1-5]/.test(pan))
    return 'mastercard';

  if (cardBinMap.humo.includes(iin4)) return 'humo';
  if (cardBinMap.uzcard.includes(iin4)) return 'uzcard';
  if (cardBinMap.maestro.includes(iin4)) return 'maestro';

  return '';
};

function checkPanIncluding(pan, beginningArray) {
  let included = false;
  beginningArray.forEach(beginning => {
    if (included) return;
    const beginningStr = String(beginning);
    if (pan.startsWith(beginningStr) || beginningStr.startsWith(pan))
      included = true;
  });
  return included;
}

function createArrayFromRange(start, end) {
  return [...Array(end - start + 1).keys()].map(x => x + start);
}

export const getPossibleCardTypes = pan => {
  if (!pan) return [];

  const possibleTypes = [];

  if (/^4/.test(pan)) possibleTypes.push('visa');

  if (checkPanIncluding(pan, createArrayFromRange(2200, 2204)))
    possibleTypes.push('mir');

  if (checkPanIncluding(pan, createArrayFromRange(2221, 2720)))
    possibleTypes.push('mastercard');

  if (checkPanIncluding(pan, cardBinMap.americanExpress))
    possibleTypes.push('american-express');

  if (checkPanIncluding(pan, cardBinMap.humo)) possibleTypes.push('humo');

  if (checkPanIncluding(pan, cardBinMap.uzcard)) possibleTypes.push('uzcard');

  if (checkPanIncluding(pan, cardBinMap.maestro)) possibleTypes.push('maestro');

  return possibleTypes;
};

export const locales = {
  az: {
    title: 'Azərbaycan',
    key: 'az',
  },

  bn: {
    title: 'বাংলা',
    key: 'bn',
  },

  de: {
    title: 'Deutsch',
    key: 'de',
  },

  en: {
    title: 'English',
    key: 'en',
  },

  es: {
    title: 'Español',
    key: 'es',
  },

  fr: {
    title: 'Français',
    key: 'fr',
  },

  id: {
    title: 'Indonesia',
    key: 'id',
  },

  in: {
    title: 'हिन्दी',
    key: 'in',
  },

  it: {
    title: 'Italiano',
    key: 'it',
  },

  kg: {
    title: 'Кыргыз тили',
    key: 'kg',
  },

  kz: {
    title: 'Қазақша',
    key: 'kz',
  },

  pl: {
    title: 'Polski',
    key: 'pl',
  },

  pt: {
    title: 'Português',
    key: 'pt',
  },

  ro: {
    title: 'Română',
    key: 'ro',
  },

  ru: {
    title: 'Русский',
    key: 'ru',
  },

  tg: {
    title: 'Тоҷикӣ',
    key: 'tg',
  },

  tr: {
    title: 'Türk',
    key: 'tr',
  },

  tz: {
    title: 'Kiswahili',
    key: 'tz',
  },

  ua: {
    title: 'Український',
    key: 'ua',
  },

  uz: {
    title: "O'zbek tili",
    key: 'uz',
  },

  jp: {
    title: '日本語',
    key: 'jp',
  },

  cn: {
    title: '中文',
    key: 'cn',
  },
};

export const paymentCards = [
  {
    title: 'Visa',
    key: 'visa',
  },
  {
    title: 'Mastercard',
    key: 'mastercard',
  },
  {
    title: 'Mir',
    key: 'mir',
  },
  {
    title: 'HUMO',
    key: 'humo',
  },
  {
    title: 'Uzcard',
    key: 'uzcard',
  },
  // {
  //   title: 'American Express',
  //   key: 'american-express',
  // },
  // {
  //   title: 'Maestro',
  //   key: 'maestro',
  // },
];
