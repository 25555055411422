<template>
  <div class="error">
    <div id="errorModal" class="error__content">
      <img
        src="@/assets/icons/ui/ui-close.svg"
        class="error__close"
        @click="handleCloseClick"
      />
      <img src="@/assets/icons/card-form/error.svg" class="error__image" />
      <p class="error__title">{{ $t('error-reasons.title') }}</p>
      <p class="error__subtext">
        {{
          $te('error-reasons.' + errorText)
            ? $t('error-reasons.' + errorText)
            : errorText
        }}
      </p>
      <UiButton class="error__try-again" @click="tryAgain">
        {{ $t('card-form.again') }}
      </UiButton>
      <UiButton
        v-if="returnUrl"
        color-type="secondary"
        class="error__return"
        @click="returnToSite"
      >
        {{ $t('card-form.return') }}
      </UiButton>
    </div>
  </div>
</template>

<script>
import UiButton from '@/components/ui/UiButton.vue';

export default {
  name: 'ErrorModal',

  components: { UiButton },

  props: {
    errorText: {
      type: String,
      default: '',
    },
  },

  computed: {
    returnUrl() {
      return this.$store.state.returnUrl;
    },
  },

  methods: {
    handleCloseClick() {
      this.$emit('close');
    },

    tryAgain() {
      this.$emit('close');
    },

    returnToSite() {
      window.location = this.returnUrl;
    },
  },
};
</script>

<style lang="scss">
.error {
  background: rgba(27, 27, 27, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    width: 343px;
    background: #fff;
    padding: 20px;
    border-radius: 16px;
    position: relative;
    box-sizing: border-box;

    @media (max-width: 480px) {
      width: unset;
      position: fixed;
      right: 16px;
      bottom: 16px;
      left: 16px;
    }
  }

  &__image {
    width: 54px;
    height: 54px;
    display: block;
    margin: 0 auto;
  }

  &__title {
    margin-top: 16px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.33px;
    text-align: center;
    color: rgba(14, 16, 17, 1);
  }

  &__subtext {
    margin-top: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.001px;
    text-align: center;
    color: rgba(111, 118, 126, 1);
  }

  &__try-again {
    margin-top: 24px;
  }

  &__return {
    margin-top: 12px;
  }

  &__close {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
}
</style>
