<template>
  <div class="header">
    <img
      v-if="isLogoShow"
      alt="logo"
      src="@/assets/icons/logotype.svg"
      class="header__logo"
    />
    <LanguageSwitcher />
  </div>
</template>

<script>
import LanguageSwitcher from '@/components/ui/LanguageSwitcher.vue';

export default {
  name: 'TheHeader',

  components: { LanguageSwitcher },
  computed: {
    isLogoShow() {
      return this.$store.getters.isLogoShow;
    },
  },
};
</script>

<style lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1090px;
  height: 32px;
  margin: 0 auto;
  position: relative;

  &__logo {
    width: 81px;
    height: 32px;
  }
}
</style>
