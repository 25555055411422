/**
 * @author feod0r
 */
/* eslint-disable import/no-extraneous-dependencies */
import axios from 'axios';
import qs from 'qs';

export default axios.create({
  baseURL: '/api',
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
});
