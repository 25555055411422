<template>
  <div class="card-list">
    <img
      v-for="(card, index) in visibleCards"
      :key="card.key"
      class="card-list__item"
      :class="{ 'card-list__item_spaced': index !== 0 }"
      :src="card.image"
    />
    <div v-show="otherCards.length > 0" class="card-list__more">
      <p>{{ `+${otherCards.length}` }}</p>
      <div class="card-list__other">
        <img
          v-for="(card, index) in otherCards"
          :key="card.key"
          class="card-list__item"
          :class="index !== 0 && 'card-list__item_spaced'"
          :src="card.image"
        />
        <img
          src="@/assets/icons/ui/tooltip-arrow.svg"
          class="card-list__arrow"
        />
      </div>
    </div>
  </div>
</template>

<script>
import humo from '@/assets/icons/cards/humo.svg';
import mastercard from '@/assets/icons/cards/mastercard.svg';
import mir from '@/assets/icons/cards/mir.svg';
import uzcard from '@/assets/icons/cards/uzcard.svg';
import visa from '@/assets/icons/cards/visa.svg';
import { paymentCards, getPossibleCardTypes } from '@/utils/Constants.js';

export default {
  name: 'CardList',

  props: {
    pan: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      paymentCards,

      visa,
      mastercard,
      mir,
      humo,
      uzcard,
    };
  },

  computed: {
    cardsWithIcons() {
      return this.paymentCards.map(card => ({
        ...card,
        image: this[card.key],
      }));
    },

    filteredCards() {
      if (!this.pan) return this.cardsWithIcons;
      const possibleTypes = getPossibleCardTypes(this.pan);
      return this.cardsWithIcons.filter(card =>
        possibleTypes.includes(card.key),
      );
    },

    visibleCards() {
      return this.filteredCards.slice(0, 3);
    },

    otherCards() {
      return this.filteredCards.slice(3);
    },
  },
};
</script>

<style lang="scss">
.card-list {
  height: 16px;
  display: flex;
  align-items: center;

  &__item {
    width: 24px;
    height: 16px;

    &_spaced {
      margin-left: 5px;
    }
  }

  &__more {
    width: 24px;
    height: 16px;
    border-radius: 2px;
    background-color: rgba(111, 118, 126, 0.64);
    margin-left: 5px;
    font-size: 10px;
    line-height: 14px;
    font-weight: 600;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;

    &:hover .card-list__other {
      display: flex;
    }
  }

  &__other {
    position: absolute;
    right: -1px;
    bottom: 22px;
    background-color: #fff;
    height: 32px;
    padding: 0 12px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.16);
    display: none;
  }

  &__arrow {
    width: 10px;
    height: 6px;
    position: absolute;
    bottom: -4px;
    right: 10px;
  }
}
</style>
