<template>
  <div class="error-page">
    <img src="@/assets/icons/error/error.svg" class="error-page__image" />
    <h1 class="error-page__title">{{ $t('error.title') }}</h1>
    <p class="error-page__text">{{ $t('error.text') }}</p>
    <UiButton
      v-if="returnUrl"
      color-type="secondary"
      class="error-page__button"
      @click="handleReturnClick"
    >
      {{ $t('card-form.return') }}
    </UiButton>
  </div>
</template>

<script>
import UiButton from '@/components/ui/UiButton.vue';

export default {
  name: 'ErrorPage',

  components: {
    UiButton,
  },

  computed: {
    returnUrl() {
      return this.$store.state.returnUrl;
    },
  },

  methods: {
    handleReturnClick() {
      window.location = this.returnUrl;
    },
  },
};
</script>

<style lang="scss">
.error-page {
  padding-top: 40px;
  width: 343px;
  margin: 0 auto;

  @media (max-width: 480px) {
    width: 100%;
  }

  &__image {
    display: block;
    margin: 0 auto;
    width: 150px;
    height: 150px;

    @media (max-width: 480px) {
      width: 115px;
      height: 115px;
    }
  }

  &__title {
    margin-top: 16px;
    color: rgba(14, 16, 17, 1);
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.47px;
    text-align: center;
  }

  &__text {
    margin-top: 8px;
    color: rgba(111, 118, 126, 1);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.009px;
    text-align: center;
  }

  &__button {
    margin-top: 24px;
  }
}
</style>
