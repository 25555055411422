<template>
  <div id="app">
    <TheHeader />
    <router-view />
    <TheFooter />
  </div>
</template>

<script>
import TheFooter from '@/components/TheFooter.vue';
import TheHeader from '@/components/TheHeader.vue';

export default {
  name: 'App',

  components: {
    TheHeader,
    TheFooter,
  },

  title() {
    return this.$t('page-title');
  },
};
</script>

<style lang="scss">
#app {
  padding: 100px 16px 0;

  @media (max-width: 480px) {
    padding-top: 56px;
  }
}
</style>
