<template>
  <div
    class="switcher"
    tabindex="0"
    @click="toggleDropdown(!isDropdownVisible)"
    @blur="toggleDropdown(false)"
  >
    <div class="switcher__current-lang">
      <img
        alt="lang"
        :src="require(`@/assets/icons/langs/${this.currentLang.key}.svg`)"
        class="switcher__lang-icon"
      />
      <p width class="switcher__current-title">
        {{ currentLang.title }}
      </p>
      <img
        alt="arrow"
        src="@/assets/icons/ui/ui-arrow.svg"
        class="switcher__current-arrow"
        :class="isDropdownVisible && 'switcher__current-arrow_active'"
      />
    </div>
    <div v-show="isDropdownVisible" class="switcher__dropdown">
      <div
        v-for="lang in langs"
        :key="lang.key"
        class="switcher__dropdown-item"
        @click="changeLang(lang.key)"
      >
        <img
          alt="lang"
          :src="require(`@/assets/icons/langs/${lang.key}.svg`)"
          class="switcher__lang-icon"
        />
        <p class="switcher__dropdown-title">{{ lang.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { locales } from '@/utils/Constants.js';

export default {
  name: 'LanguageSwitcher',

  data() {
    return {
      isDropdownVisible: false,
    };
  },

  computed: {
    currentLang() {
      return locales[this.$i18n.locale];
    },

    langs() {
      return Object.values(locales);
    },
  },

  methods: {
    changeLang(langKey) {
      this.$i18n.locale = langKey;
    },

    toggleDropdown(value) {
      this.isDropdownVisible = value;
    },
  },
};
</script>

<style lang="scss">
.switcher {
  position: relative;

  @media (max-width: 480px) {
    position: static;
  }

  &__current {
    &-lang {
      height: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &-title {
      margin-left: 8px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      color: #6f767e;
    }

    &-arrow {
      width: 16px;
      height: 16px;
      margin-left: 4px;
      transition: transform 0.4s;

      &_active {
        transform: rotate(180deg);
      }
    }
  }

  &__lang-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  &__dropdown {
    position: absolute;
    right: 0;
    top: 30px;
    width: 343px;
    border-radius: 12px;
    height: 196px;
    overflow-y: auto;
    box-shadow: 0px 4px 24px 0px #00000014;
    padding: 8px 4px 8px 8px;
    scrollbar-width: thin;
    scrollbar-color: #dcdfe5 #fff;
    z-index: 10;
    background-color: #fff;

    @media (max-width: 480px) {
      top: 36px;
      width: unset;
      left: 0;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #dcdfe5;
      border-radius: 2px;
    }

    &-item {
      height: 40px;
      border-radius: 8px;
      background-color: #fff;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 0 15px;

      &:hover {
        background-color: #f3f5f9;
      }
    }

    &-title {
      color: #0e1011;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      margin-left: 10px;
    }
  }
}
</style>
