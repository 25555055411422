<template>
  <div class="card-form">
    <p class="card-form__title">{{ $t('card-form.title') }}</p>
    <p v-if="amountLabel" class="card-form__amount">{{ amountLabel }}</p>
    <div class="card-form__divider"></div>
    <ErrorCard v-show="errorCardVisible" error-text="no-request-data" />
    <UiInput
      v-model="pan"
      card
      pattern="\d*"
      class="card-form__card"
      :placeholder="$t('card-form.pan.label')"
      :title="$t('card-form.pan.label')"
      :show-success="panStatus.success"
      :show-error="panStatus.error"
      :error-text="$t(`card-form.pan.${panStatus.errorText}`)"
    >
      <CardList :pan="pan" />
    </UiInput>
    <div class="card-form__input-wrapper">
      <UiInput
        v-if="fieldsVision.expiration_date"
        v-model="expiration_date"
        pattern="\d*"
        :max-length="5"
        :regex="/^\d/"
        :title="$t('card-form.date.label')"
        :placeholder="$t('card-form.date.label')"
        :show-success="dateStatus.success"
        :show-error="dateStatus.error"
        :error-text="$t(`card-form.date.${dateStatus.errorText}`)"
        :focus="dateStatus.focus"
        class="card-form__date"
      />
      <UiInput
        v-if="fieldsVision.cvv"
        v-model="cvv"
        pattern="\d*"
        :max-length="3"
        :regex="/\d+$/"
        title="CVV/CVC"
        placeholder="CVC"
        :show-success="cvvStatus.success"
        :show-error="cvvStatus.error"
        :error-text="$t(`card-form.cvv.${cvvStatus.errorText}`)"
        :focus="cvvStatus.focus"
        class="card-form__cvv"
      />
    </div>
    <UiInput
      v-if="fieldsVision.cardholder_name"
      v-model="cardholder_name"
      :title="$t('card-form.cardholder.label')"
      :placeholder="$t('card-form.cardholder.label')"
      :show-success="nameStatus.success"
      :show-error="nameStatus.error"
      :error-text="$t(`card-form.cardholder.${cvvStatus.errorText}`)"
      :focus="nameStatus.focus"
      uppercase
      class="card-form__cardholder"
    />
    <div v-if="isAdditionalFields">
      <div
        v-for="field in additionalFields"
        :key="field.key"
        class="card-form__additional-field"
      >
        <Dropdown
          v-if="isDropdown(field.type)"
          :option-names="field.dropdownValues"
          :class="{ 'invalid-value': field.isError }"
          :max-item="dropdown.maxItems"
          :placeholder="$t(field.placeholder) || ''"
          @selected="dropdownSelected(field.key, $event)"
        />
        <UiInput
          v-else
          v-model="field.value"
          :title="field.name || ''"
          :type="field.type"
          :placeholder="$t(field.placeholder || '')"
          @change="additionalFieldChange(field)"
          @onBlur="additionalFieldChange(field)"
        />
      </div>
    </div>
    <UiButton
      :disabled="processLoading"
      class="card-form__replenish"
      @click="submitForm"
    >
      {{ $t('card-form.deposit') }}
    </UiButton>
    <UiButton
      v-if="returnUrl"
      button-type="submit"
      :disabled="processLoading"
      class="card-form__return"
      @click="handleReturnClick"
    >
      {{ $t('card-form.return') }}
    </UiButton>
    <ErrorModal
      v-show="errorModalVisible"
      :error-text="errorText"
      @close="closeErrorModal"
    />
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { getRequestInfo, processPayment } from '@/api/payments.js';
import Dropdown from '@/components/Fields/Dropdown.vue';
import CardList from '@/components/ui/CardList.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiInput from '@/components/ui/UiInput.vue';
import { getCardType, merchantRequiredFields } from '@/utils/Constants.js';

import ErrorCard from './ErrorCard.vue';
import ErrorModal from './ErrorModal.vue';

export default {
  name: 'CardForm',

  components: {
    Dropdown,
    UiInput,
    UiButton,
    CardList,
    ErrorModal,
    ErrorCard,
  },

  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      fieldsList: ['pan', 'cardholder_name', 'expiration_date', 'cvv'],
      fieldsStatusesList: [
        'panStatus',
        'dateStatus',
        'cvvStatus',
        'nameStatus',
      ],
      pan: '',
      cardholder_name: '',
      expiration_date: '',
      cvv: '',
      cardType: '',
      errorText: '',
      dropdown: {
        maxItems: 10,
      },
      data: {},
      fieldsVision: {
        pan: true,
        cvv: true,
        expiration_date: true,
        cardholder_name: true,
      },
      isLoading: true,
      additionalFields: {},

      processLoading: false,

      panStatus: {
        success: false,
        error: false,
        focus: false,
        errorText: 'error-empty',
      },

      dateStatus: {
        success: false,
        error: false,
        focus: false,
        errorText: 'error-empty',
      },

      cvvStatus: {
        success: false,
        error: false,
        focus: false,
        errorText: 'error-empty',
      },

      nameStatus: {
        success: false,
        error: false,
        focus: false,
        errorText: 'error-empty',
      },

      errorModalVisible: false,
      errorCardVisible: false,
    };
  },
  computed: {
    amountLabel() {
      return this.data?.amount?.toLocaleString(undefined, {
        style: 'currency',
        currency: this.data.currency,
      });
    },
    isAdditionalFields() {
      return Object.keys(this.additionalFields).length;
    },

    returnUrl() {
      return this.$store.state.returnUrl;
    },
  },
  watch: {
    pan() {
      this.panStatus.error = false;

      const cleanPan = this.pan.replace(/[^0-9]*/g, '');

      this.cardType = getCardType(cleanPan);
      this.pan = cleanPan
        .match(/.{0,4}/g)
        .filter(Boolean)
        .join(' ');

      if (this.pan.length === 19) {
        this.panStatus.success = true;
        this.dateStatus.focus = true;
      } else {
        this.panStatus.success = false;
      }
    },
    expiration_date() {
      this.dateStatus.error = false;

      if (
        this.expiration_date.length === 1 &&
        !['0', '1'].includes(this.expiration_date[0])
      ) {
        this.expiration_date = `0${this.expiration_date}`;
      }

      if (
        this.expiration_date.length === 2 &&
        parseInt(this.expiration_date, 10) > 12
      ) {
        this.expiration_date = `0${this.expiration_date[0]}/${this.expiration_date[1]}`;
      }

      const cleanDate = this.expiration_date.replace(/[^0-9]*/g, '');
      this.expiration_date = cleanDate
        .match(/[0-9]{0,2}/g)
        .filter(Boolean)
        .join('/');

      if (this.expiration_date.length === 5) {
        const currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() - 1);
        const [month, year] = this.expiration_date.split('/');
        const expiredDate = new Date(`20${year}`, month - 1);
        if (expiredDate > currentDate) {
          this.dateStatus.success = true;
          this.cvvStatus.focus = true;
        } else {
          this.dateStatus.success = false;
        }
      } else {
        this.dateStatus.success = false;
      }
    },

    cvv() {
      this.cvvStatus.error = false;

      if (this.cvv.length === 3) {
        this.cvvStatus.success = true;
        this.nameStatus.focus = true;
      } else {
        this.cvvStatus.success = false;
      }
    },

    cardholder_name() {
      this.nameStatus.error = false;
      this.errorCardVisible = false;

      if (
        this.cardholder_name.length > 4 &&
        /^[a-zA-Z]+(\s[a-zA-Z]+){1,3}$/.test(this.cardholder_name)
      )
        this.nameStatus.success = true;
      else this.nameStatus.success = false;
    },
  },
  mounted() {
    this.validateRequest();
  },
  methods: {
    isDropdown(type) {
      return type === 'dropdown';
    },

    dropdownSelected(fieldKey, event) {
      this.additionalFields[fieldKey].value = event || '';
      this.isAdditionalFieldValid(this.additionalFields[fieldKey]);
    },

    async validateRequest() {
      const requestId = window.location.pathname.replace(/^.*\/([^/]+)$/, '$1');
      const loader = this.$loading.show({
        loader: 'dots',
        width: 50,
        opacity: 1,
        transition: null,
      });

      try {
        this.data = await getRequestInfo(requestId);
        const { status, returnUrl, merchantName, additionalFields } = this.data;

        if (merchantName) {
          this.$store.commit('setCurrentProvider', merchantName);
        }

        if (!status) {
          Sentry.setContext('details', {
            message: 'No status from data',
            data: this.data,
          });
          Sentry.captureMessage('No status from data');
          this.$store.commit('setReturnUrl', returnUrl);
          return this.$router.push('/not-found');
        }

        const checkMerchant =
          merchantName &&
          Object.keys(merchantRequiredFields).find(name =>
            merchantName.match(new RegExp(`${name}.*`)),
          );

        if (checkMerchant) {
          this.fieldsList = merchantRequiredFields[checkMerchant];

          Object.keys(this.fieldsVision).forEach(element => {
            this.fieldsVision[element] = this.fieldsList.includes(element);
          });
        }
        this.initializeAdditionalFields(additionalFields);
      } catch (err) {
        Sentry.captureException(err);
        this.$router.push('/not-found');
      } finally {
        loader.hide();
        this.isLoading = false;
      }
    },

    isAdditionalFieldValid(field) {
      const { value, regex, key } = field;

      if (regex.test(value)) {
        this.$set(this.additionalFields[key], 'isError', false);

        return true;
      }

      this.$set(this.additionalFields[key], 'isError', true);

      return false;
    },

    checkIsFormValid() {
      if (
        this.fieldsList.includes('pan') &&
        (!this.pan || this.pan.length < 19)
      ) {
        this.panStatus.error = true;
        this.panStatus.errorText = !this.pan ? 'error-empty' : 'error-length';
      }

      if (this.fieldsList.includes('expiration_date')) {
        if (this.expiration_date.length < 5) {
          this.dateStatus.error = true;
          this.dateStatus.errorText = 'error-empty';
        } else {
          const currentDate = new Date();
          currentDate.setMonth(currentDate.getMonth() - 1);
          const [month, year] = this.expiration_date.split('/');
          const expiredDate = new Date(`20${year}`, month - 1);
          if (expiredDate < currentDate) {
            this.dateStatus.error = true;
            this.dateStatus.errorText = 'error-expired';
          }
        }
      }

      if (this.fieldsList.includes('cvv') && this.cvv.length < 3)
        this.cvvStatus.error = true;

      if (this.fieldsList.includes('cardholder_name')) {
        if (!this.cardholder_name.length) this.nameStatus.error = true;
        else if (!/^[a-zA-Z]+(\s[a-zA-Z]+){1,3}$/.test(this.cardholder_name))
          this.errorCardVisible = true;

        if (
          this.isAdditionalFields &&
          Object.entries(this.additionalFields).some(
            ([, field]) => !this.isAdditionalFieldValid(field),
          )
        ) {
          return false;
        }
      }

      return (
        this.fieldsStatusesList.every(status => !this[status].error) &&
        !this.errorCardVisible
      );
    },

    additionalFieldChange(field) {
      this.isAdditionalFieldValid(field);
    },

    async submitForm() {
      if (!this.checkIsFormValid() || this.processLoading) {
        return false;
      }

      const loader = this.$loading.show({
        loader: 'dots',
        width: 50,
        opacity: 1,
        transition: null,
      });

      try {
        this.processLoading = true;
        const body = this.createPaymentBody();
        const response = await processPayment(this.data.requestId, body).catch(
          err => (err.response ? { error: err.response.data.error } : err),
        );

        if (response.error) {
          this.errorText = response.error;
          this.openErrorModal();
          return;
        }

        const {
          method,
          source,
          data,
          targetBlank = false,
          contentType,
        } = response.data;

        const form = document.createElement('form');
        form.setAttribute('action', source);
        form.setAttribute('method', method);

        Object.keys(data).forEach(name => {
          const input = document.createElement('input');
          input.setAttribute('name', name);
          input.setAttribute('value', data[name]);
          input.setAttribute('type', 'hidden');
          form.appendChild(input);
        });

        document.querySelector('body').appendChild(form);

        if (targetBlank) {
          form.setAttribute('target', '_blank');
          form.setAttribute('rel', 'noopener noreferrer');
        }

        if (contentType) {
          form.setAttribute('enctype', contentType);
        }

        form.submit();
      } catch (err) {
        Sentry.captureException(err);
      } finally {
        this.processLoading = false;
        loader.hide();
      }
    },

    createPaymentBody() {
      const body = this.fieldsList.reduce(
        (obj, key) => ({ ...obj, [key]: this[key] }),
        {},
      );

      if (this.isAdditionalFields) {
        body.additionalFields = Object.entries(this.additionalFields).reduce(
          (acc, [key, fieldData]) => {
            acc[key] = fieldData.value;

            return acc;
          },
          {},
        );
      }

      return body;
    },

    initializeAdditionalFields(additionalFields) {
      if (Array.isArray(additionalFields) && additionalFields) {
        this.additionalFields = additionalFields.reduce((acc, field) => {
          if (field) {
            acc[field.key] = {
              ...field,
              type: field.type || 'text',
              regex: new RegExp(field.regex || '.*'),
              value: field.defaultValue || '',
              isError: false,
            };
          }

          return acc;
        }, {});
      }
    },

    handleReturnClick() {
      window.location = this.returnUrl;
    },

    openErrorModal() {
      const el = document.querySelector('#errorModal');
      disableBodyScroll(el);
      this.errorModalVisible = true;
    },

    closeErrorModal() {
      const el = document.querySelector('#errorModal');
      enableBodyScroll(el);
      this.errorModalVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-form {
  width: 343px;
  margin: 0 auto;
  padding-top: 40px;

  @media (max-width: 480px) {
    width: 100%;
  }

  &__title {
    color: rgb(111, 118, 126);
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    letter-spacing: 0.001px;
  }

  &__amount {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.33000001311302185px;
    margin-top: 8px;
  }

  &__divider {
    margin: 24px 0;
    background: rgb(243, 245, 249);
    height: 1px;
  }

  &__additional-field {
    margin-top: 16px;
  }

  &__input-wrapper {
    margin-top: 16px;
    display: flex;

    & > div {
      flex: 1;
    }
  }

  &__cvv {
    margin-left: 16px;
  }

  &__cardholder {
    margin-top: 16px;
  }

  &__replenish {
    margin-top: 24px;
    width: 100%;
  }

  &__return {
    margin-top: 16px;
    width: 100%;
  }
}
</style>
