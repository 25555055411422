<template>
  <div class="footer">
    <img
      v-if="isLogoShow"
      src="@/assets/icons/card-form/powered-by.svg"
      class="footer__powered"
    />
    <div class="footer__partners">
      <img
        v-for="(partner, index) in partners"
        :key="partner"
        :src="require(`@/assets/icons/footer/${partner}.png`)"
        class="footer__partner"
        :class="{ footer__partner_spaced: index !== 0 }"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheFooter',

  data() {
    return {
      partners: ['mastercard', 'visa', 'mir'],
    };
  },
  computed: {
    isLogoShow() {
      return this.$store.getters.isLogoShow;
    },
  },
};
</script>

<style lang="scss">
.footer {
  margin-top: 24px;

  &__powered {
    width: 103px;
    height: 22px;
    margin: 0 auto;
    display: block;
  }

  &__partners {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__partner {
    height: 24px;

    &_spaced {
      margin-left: 24px;
    }
  }
}
</style>
