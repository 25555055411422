<template>
  <button
    :key="colorType + disabled"
    :type="buttonType"
    class="button"
    :class="[buttonClass, disabled && 'button_disabled']"
    @click="handleClick"
  >
    <div class="button__top-layer"></div>
    <slot v-if="!loading" />
    <img
      v-else
      src="@/assets/icons/ui/loading-spinner.svg"
      class="button__loading-spinner"
    />
  </button>
</template>

<script>
export default {
  name: 'UiButton',

  props: {
    buttonType: {
      type: String,
      default: null,
    },

    colorType: {
      type: String,
      default: 'primary',
      validate: v => ['primary', 'secondary'].includes(v),
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    buttonClass() {
      if (this.colorType === 'secondary') return 'button_secondary';
      return 'button_primary';
    },
  },

  methods: {
    handleClick() {
      if (this.disabled || this.loading) return;
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss">
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #fff;
  padding: 0 20px;
  height: 44px;
  position: relative;
  cursor: pointer;
  border: none;
  outline: none;
  overflow: hidden;
  width: 100%;

  &_primary {
    background: radial-gradient(
      29.95% 89.28% at 98.39% 94.32%,
      #00dc5b 0%,
      #12b857 100%
    );

    .button__top-layer {
      display: block;
    }

    &:hover .button__top-layer {
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.08),
        rgba(255, 255, 255, 0.08)
      );
    }

    &:active .button__top-layer {
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.08),
        rgba(0, 0, 0, 0.08)
      );
    }

    &.button_disabled {
      .button__top-layer {
        background: rgba(255, 255, 255, 0.5) !important;
      }
    }
  }

  &_secondary {
    background-color: rgba(26, 29, 31, 1);

    &:hover {
      background-color: rgba(38, 41, 44, 1);
    }

    &:active {
      background-color: rgba(21, 23, 25, 1);
    }

    &.button_disabled {
      background-color: rgb(26, 29, 31);
    }
  }

  &_disabled {
    cursor: not-allowed;
  }

  &__top-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    touch-action: none;
    display: none;
  }

  &__loading-spinner {
    width: 20px;
    height: 20px;
  }
}
</style>
