import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    returnUrl: '',
    disableLogoProvider: ['epulw', 'epulw-RUB', 'epulw-AZN'],
    currentProvider: '',
  },
  mutations: {
    setReturnUrl(state, value) {
      state.returnUrl = value;
    },
    setCurrentProvider(state, value) {
      state.currentProvider = value;
    },
  },
  getters: {
    isLogoShow(state) {
      return !state.disableLogoProvider.includes(state.currentProvider);
    },
  },
});
