import * as Sentry from '@sentry/vue';
import Vue from 'vue';
import VueLoading from 'vue-loading-overlay';
import VuePageTitle from 'vue-page-title';

import App from './App.vue';
import i18n from './i18n.js';
import 'vue-loading-overlay/dist/vue-loading.css';
import router from './router/index.js';
import './styles/index.scss';
import store from './store';

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DOMAIN,
  tracesSampleRate: 1.0,
});

Vue.config.productionTip = false;

Vue.use(VueLoading);
Vue.use(VuePageTitle);

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app');
