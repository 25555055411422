import requester from '@/utils/api.js';

export const getRequestInfo = requestId =>
  requester.post(`/validate/${requestId}`).then(res => res.data);

export const processPayment = (requestId, body) =>
  requester
    .post(`/process-payment/${requestId}`, { body })
    .then(res => res.data);

export const confirmCode = (requestId, code) =>
  requester.post(`/confirm/${requestId}`, { code }).then(res => res.data);
