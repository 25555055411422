<template>
  <div class="error-card">
    <img src="@/assets/icons/card-form/error.svg" class="error-card__image" />
    <p class="error-card__text">
      {{
        $te('error-reasons.' + errorText)
          ? $t('error-reasons.' + errorText)
          : errorText
      }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'ErrorCard',

  props: {
    errorText: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.error-card {
  margin-bottom: 16px;
  background: rgba(229, 62, 52, 0.1);
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 12px 16px;

  &__image {
    width: 16px;
    height: 16px;
  }

  &__text {
    margin-left: 12px;
    color: rgba(229, 62, 52, 1);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.009px;
  }
}
</style>
