import Vue from 'vue';
import VueRouter from 'vue-router';

import CardForm from '../components/Card/CardForm.vue';
import ConfirmForm from '../components/Confirmation/ConfirmForm.vue';
import ErrorPage from '../components/Error/ErrorPage.vue';
// import SuccessPage from '../components/success/SuccessPage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/payment/:id',
    name: 'CardForm',
    component: CardForm,
  },
  {
    path: '/confirm/:id',
    name: 'ConfirmationForm',
    component: ConfirmForm,
  },
  // {
  //   path: '/success',
  //   name: 'SuccessPage',
  //   component: SuccessPage,
  // },
  // error pages
  {
    path: '/not-found',
    name: 'ErrorPage',
    component: ErrorPage,
  },
  {
    path: '*',
    component: ErrorPage,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
