<template>
  <div class="input-wrapper">
    <div
      class="input-container"
      :class="showError && 'input-container_error'"
      @click="handleContainerClick"
    >
      <div v-show="readonly" class="input-container__plug"></div>
      <transition name="fade">
        <p v-show="isFocused" class="input-container__title">
          {{ title }}
        </p>
      </transition>
      <input
        ref="input"
        v-model="internalValue"
        :readonly="readonly"
        :type="type"
        :placeholder="placeholder"
        :pattern="pattern"
        class="input"
        @input="onInput($event.target.value)"
        @change="$emit('change', $event.target.value)"
        @paste="onInput($event.target.value)"
        @blur="onBlur"
        @focus="onFocus"
      />
      <slot />
      <img
        v-show="showSuccess"
        alt="success"
        src="@/assets/icons/ui/ui-success.svg"
        class="input-success"
      />
    </div>
    <p v-show="showError && errorText" class="error-text">{{ errorText }}</p>
  </div>
</template>

<script>
import { conformToMask } from 'text-mask-core';

const walletMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /[\d|*]/,
  /[\d|*]/,
  /[\d|*]/,
  /[\d|*]/,
  ' ',
  /[\d|*]/,
  /[\d|*]/,
  /[\d|*]/,
  /[\d|*]/,
  ' ',
  /[\d|*]/,
  /[\d|*]/,
  /[\d|*]/,
  /[\d|*]/,
  ' ',
  /[\d|*]/,
  /[\d|*]/,
];

const WALLET_LENGTH = 16;

export default {
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },

    value: {
      type: [String, Number],
      default: null,
    },

    placeholder: {
      type: String,
      default: null,
    },

    title: {
      type: String,
      default: null,
    },

    card: {
      type: Boolean,
      default: false,
    },

    maxLength: {
      type: Number,
      default: null,
    },

    regex: {
      type: RegExp,
      default: null,
    },

    type: {
      type: String,
      default: 'text',
    },

    showSuccess: {
      type: Boolean,
      default: false,
    },

    showError: {
      type: Boolean,
      default: false,
    },

    errorText: {
      type: String,
      default: '',
    },

    uppercase: {
      type: Boolean,
      default: false,
    },

    focus: {
      type: Boolean,
      default: false,
    },

    pattern: {
      type: String,
      default: null,
    },
  },

  emits: ['input', 'change', 'onBlur', 'onFocus'],

  data() {
    return {
      isFocused: false,
      internalValue: '',
    };
  },

  watch: {
    value(v) {
      this.onInput(v ?? '');
    },

    focus(newVal) {
      if (newVal) this.$refs.input.focus();
    },
  },

  created() {
    this.onInput(this.value ?? '');
  },

  methods: {
    handleContainerClick() {
      this.$refs.input?.focus();
    },

    onKeydown(e) {
      if (e.key === 'Backspace') {
        const { value, selectionStart, selectionEnd } = e.target;

        if (
          value[selectionStart - 1] === ' ' &&
          selectionStart === selectionEnd
        ) {
          e.preventDefault();

          const result =
            value.slice(0, selectionStart - 2) +
            value.slice(selectionStart, value.length);

          this.onInput(result);
        }

        return;
      }

      const regexTest = this.regex && !this.regex.test(e.key);
      const numberTest = this.type === 'number' && !/[0-9]/.test(e.key);

      if (regexTest || numberTest) {
        e.preventDefault();
        e.stopPropagation();
      }
    },

    onInput(value) {
      if (this.card) {
        const plainValue = value.replace(/ /g, '') ?? '';

        const validValue =
          plainValue.length > WALLET_LENGTH
            ? plainValue.substr(0, WALLET_LENGTH)
            : plainValue;

        const { conformedValue } = conformToMask(validValue, walletMask, {
          guide: false,
          keepCharPositions: false,
        });

        this.internalValue = conformedValue.trim();

        this.$emit('input', validValue);
      } else {
        let newValue = value;

        if (this.maxLength && this.maxLength < value.length) {
          newValue = this.oldValue ?? '';
        }

        if (this.regex && newValue && !this.regex.test(newValue)) {
          newValue = this.oldValue ?? '';
        }

        if (this.uppercase) {
          newValue = newValue.toUpperCase();
        }

        this.oldValue = newValue;

        this.internalValue = newValue;
        this.$emit('input', newValue);
      }
    },

    onClear() {
      this.onInput('');
      this.$refs.input.focus();
    },

    onBlur() {
      this.isFocused = false;
      this.$emit('onBlur');
    },

    onFocus() {
      this.isFocused = true;
      this.$emit('onFocus');
    },
  },
};
</script>

<style lang="scss">
.input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  padding: 0 16px;
  background-color: rgb(243, 245, 249);
  position: relative;
  border-radius: 10px;
  cursor: text;

  &_error {
    background-color: rgba(255, 239, 238, 1);
    box-shadow: 0 0 0 1px rgba(229, 62, 52, 1) inset;
  }

  &__plug {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
  }

  &__title {
    font-size: 10px;
    line-height: 14px;
    color: rgb(111, 118, 126);
    position: absolute;
    top: 5px;
    left: 16px;
  }
}

.input {
  outline: none;
  background: transparent;
  border: none;
  -moz-appearance: textfield;
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  transition: margin 0.3s;
  width: 100%;
  flex: 1;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: rgb(111, 118, 126);
    text-transform: uppercase;
  }

  &:focus {
    margin-top: 14px;
  }
}

.input-success {
  width: 16px;
  height: 16px;
  margin-left: 10px;
}

.error-text {
  margin-top: 5px;
  color: rgba(250, 117, 90, 1);
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.2px;
}

.fade {
  &-enter-active,
  &-leave-active {
    transition: opacity 0.25s;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }
}
</style>
